<style scoped>
.home {
  width: 100%;
  height: 100vh;
  text-align: center;
  background: url("../assets/problemBg.jpg");
  background-size: 100% 100%;
}
.leitai-header {
  width: 100%;
  height: 80px;
  line-height: 80px;
  color: #fff;
  font-size: 23px;
  font-weight: 500;
  position: relative;
}
.leitai-header .leitai-back img {
  position: absolute;
  top: 28px;
  left: 20px;
  width: 16pt;
  height: 16pt;
}
.leitai-header .btn {
  position: absolute;
  top: 28px;
  right: 20px;
  line-height: 23px;
  font-size: 20px;
}
.leitai-header .btn span {
  font-size: 16px;
}
.middle {
  display: inline-block;
  width: 0px;
  height: 100%;
  vertical-align: middle;
}
.bodyScroll {
  width: 100%;

  height: calc(100vh - 120px);
  overflow-y: scroll;
}
.body {
  width: 90%;
  /* margin: 3vh 5% 0; */
  /* height: 77vh; */
  margin: 0 auto;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(208, 35, 36, 0.05);
  overflow: hidden;
}
.body .problemHeader {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.body .problemHeader .problemIndex {
  width: 100%;
  height: 50px;
  line-height: 30px;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 2px solid #fafafa;
  text-align: left;
  padding: 10px;
}
.body .problemHeader .problemIndex > div {
  display: inline-block;
  vertical-align: top;
}
.body .problemHeader .problemIndex > div:first-child {
  width: 3px;
  height: 20px;
  margin-top: 4px;
  border: 2px solid rgb(169, 151, 141);
}
.body .problemHeader .problemIndex > div:nth-child(2) {
  margin-left: 7px;
}
.body .problemHeader .problemIndex > div:nth-child(3) {
  float: right;
  margin-right: 10px;
}
.body .problemHeader .problemTitle {
  font-size: 18px;
  font-weight: bold;
  padding: 15px;
  text-align: left;
}
.problemChooseBox {
  width: 100%;
  padding: 0 15px;
  overflow-y: scroll;
}
.problemChooseBox .chooseLine {
  width: 100%;
  padding: 6px 15px;
  background: rgb(247, 249, 251);
  border: 1px solid rgb(241, 241, 244);
  color: rgb(90, 96, 99);
  border-radius: 5px;
  box-sizing: border-box;
  text-align: left;
  letter-spacing: 2px;
  margin: 10px 0;
  font-size: 16px;
  /* text-overflow: ellipsis;  */
  /*让截断的文字显示为点点。还有一个值是clip意截断不显示点点*/
  /* white-space: nowrap; */
  /*让文字不换行*/
  /* overflow: hidden;  */
  /*超出要隐藏*/
  transition: 0.2s;
}
.companyAndSource {
  width: 100%;
  padding: 15px;
  text-align: left;
}
.companyAndSource > div > div {
  /* display: inline-block; */
  color: rgb(41, 151, 78);
  background: rgb(223, 244, 228);
  padding: 3px 7px;
  margin: 5px 0;
  font-size: 13px;
}
.answerTip {
  color: rgb(169, 151, 141);
  width: 100px;
  float: right;
  font-size: 15px;
  margin: 10px 0;
}
</style>
<style scoped>
.lineErr {
  border: 1px solid #f00 !important;
  color: #f00;
}
.lineSuccess {
  border: 1px solid rgb(65, 206, 65) !important;
  color: rgb(65, 206, 65);
}
.lineChoose {
  border: 1px solid #f60 !important;
  color: #f60;
}
</style>
<template>
  <div class="home">
    <!-- 头部信息 -->
    <div class="leitai-header ">
      {{ activity.name }}
      <div class="leitai-back">
        <div class="middle"></div>
        <img @click="back" src="../assets/back.png" alt="" />
      </div>
      <!-- v-if="index !== allProblem" -->
      <div class="btn">
        <div class="middle"></div>
        <span @click="preProblem">上一题</span>
        <span @click="nextProblem">下一题</span>
      </div>
    </div>
    <div class="bodyScroll">
      <!-- 答题框信息 -->
      <div class="body">
        <div class="problemHeader">
          <div class="problemIndex">
            <div></div>
            <div>
              <span v-if="type == 'select'">单选题</span>
              <span v-if="type == 'multi'">多选题</span>
              <span v-if="type == 'check'">判断题</span>
              <span v-if="type == 'blank'">填空题</span>
            </div>
            <div>
              <span style="font-size:30px">{{ index }}</span>
              <span style="color:#ccc">/{{ allProblem }}</span>
            </div>
          </div>
          <div class="problemTitle">
            <div class="middle"></div>
            <div style="display:inline-block">
              {{ problem.problem }}
            </div>
          </div>
          <div class="problemChooseBox">
            <div
              v-for="(item, index) in problem.choose"
              :key="index"
              :class="chooseStyle[index]"
            >
              <span v-if="problem.type == 'select' || problem.type == 'multi'">
                {{ chooseNum[index] }}.</span
              >{{ item }}
            </div>
          </div>
          <div
            v-if="
              (problem.company && problem.company !== '') ||
                (problem.source && problem.source !== '')
            "
            class="companyAndSource"
          >
            <div v-if="problem.company && problem.company !== ''">
              <div style="display:inline-block">
                出题单位：{{ problem.company }}
              </div>
            </div>
            <div v-if="problem.source && problem.source !== ''">
              <div style="display:inline-block">
                题目来源：{{ problem.source }}
              </div>
            </div>
          </div>
          <!-- <div class="answerTip">
            <i
              style="margin-right:5px"
              class="fa fa-lightbulb-o"
              aria-hidden="true"
            ></i
            >查看提示
          </div> -->
        </div>
      </div>
      <div class="body" style="margin-top:20px;">
        <div class="problemHeader">
          <div class="problemIndex">
            <div></div>
            <div>
              <!-- <span>答案解析</span> -->
              <span>正确答案:&nbsp;{{ correctAnswer }}</span>
            </div>
          </div>
          <!-- <div style="width:100%;text-align:left;padding:15px">
            <h3>正确答案:{{ correctAnswer }}</h3>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import _ from "lodash";
import { wxConPost } from "../api/httpApi"; // Data request API
import globalData from "../api/globalData"; // Data request API
export default {
  name: "wrongTopics",
  data() {
    return {
      title: "错题页面",
      activityId: "",
      problems: [],
      problem: {},
      index: 1, // 正在作答的题目的顺序
      allProblem: 0, // 题目总数
      type: "over", // 正在作答的题目类型
      chooseStyle: [], // 选项的页面格式
      chooseNum: ["A", "B", "C", "D", "E", "F"], // 题目序列号
      chooseBol: ["正确", "错误"], //判断题答案
      selectAnswer: "", // 单选题答案
      multiAnswer: [], // 多选题答案
      checkAnswer: "", // 判断题答案
      fillAnswer: "", // 填空题答案
      activity: {}, // 正在参与的活动
      bottomStatus: "button", // "result" | "button",
      correctAnswer: "",
      correctStatus: false,
      currectPage: 1, // 分页
      wrongSelect: [],
    };
  },
  methods: {
    // 确认当前答题
    async confirm() {
      // 判断题型  选择题判断是否选中了答案  填空题判断填空中是否有字符串
      await this.checkProblem();
      this.correctAnswer = this.problem.answer.join("、");
      this.bottomStatus = "result";
    },
    // 上一题
    preProblem() {
      this.bottomStatus = "button";
      this.index--;
      console.log("after", this.index);
      if (this.index < 1) {
        this.index = 1;
      }
      console.log(this.index);
      this.problem = this.problems[this.index - 1];
      this.type = this.problem.type;
      console.log(this.problem);
      // 去掉、
      let cacheAnswer = this.problem.answer.filter((item) => item !== " ");
      this.correctAnswer = cacheAnswer.join("、");
      const mewChooseStyle = [];
      let wrongOption = this.wrongSelect[this.index - 1];
      if (this.problem.choose) {
        for (const item of this.problem.choose) {
          mewChooseStyle.push("chooseLine");
        }
        if (Array.isArray(wrongOption)) {
          // 多选题
          wrongOption.forEach((item) => {
            const index = this.chooseNum.indexOf(item);
            mewChooseStyle[index] = "chooseLine lineChoose";
          });
        } else if (/^[\x00-\xff]/.test(wrongOption)) {
          const index = this.chooseNum.indexOf(wrongOption);
          mewChooseStyle[index] = "chooseLine lineChoose";
        } else {
          const index = this.chooseBol.indexOf(wrongOption);
          mewChooseStyle[index] = "chooseLine lineChoose";
        }
      }
      this.chooseStyle = mewChooseStyle;
    },
    // 下一题
    async nextProblem() {
      this.bottomStatus = "button";
      this.index++;
      if (this.index >  this.problems.length) {
        this.index = this.problems.length ;
      }
      console.log(this.index,this.problem.problem );
      this.problem = this.problems[this.index - 1];
      this.type = this.problem.type;
      console.log(this.problem);
      // 去掉、
      let cacheAnswer = this.problem.answer.filter((item) => item !== " ");
      this.correctAnswer = cacheAnswer.join("、");
      const mewChooseStyle = [];
      let wrongOption = this.wrongSelect[this.index - 1];
     
      if (this.problem.choose) {
        for (const item of this.problem.choose) {
          mewChooseStyle.push("chooseLine");
        }
        if (Array.isArray(wrongOption)) {
          // 多选题
          wrongOption.forEach((item) => {
            const index = this.chooseNum.indexOf(item);
            mewChooseStyle[index] = "chooseLine lineChoose";
          });
        } else if (/^[\x00-\xff]/.test(wrongOption)) {
          const index = this.chooseNum.indexOf(wrongOption);
          mewChooseStyle[index] = "chooseLine lineChoose";
        } else {
          const index = this.chooseBol.indexOf(wrongOption);
          mewChooseStyle[index] = "chooseLine lineChoose";
        }
      }
      this.chooseStyle = mewChooseStyle;
      // 每10道题查询一次   剩余最后一道题时查询一次
      // if (this.index % 10 == 9) {
      //   if (this.allProblem > this.index + 1) {
      //     this.initProb();
      //   }
      // }
    },
    // 返回上一页
    back() {
      this.$router.replace("/activity-main");
    },
    // initProb  init初始状态
    async initProb(init) {
      let { wrongSelect, wrongTopics } = this.$route.query;
      this.wrongSelect = wrongSelect;
      console.log(this.$route.query);
      console.log(wrongTopics);
      this.problems = wrongTopics;
      if (init == true && this.problems.length > 0) {
        // 如果为第一次查询需选择第一个  之后的只需
        if (this.currectPage == 1) {
          this.problem = this.problems[0];
          this.index = 1;
          this.chooseStyle = [];
          this.type = this.problem.type;
          // 去掉、
          let cacheAnswer = this.problem.answer.filter((item) => item !== " ");
          this.correctAnswer = cacheAnswer.join("、");
          // 显示错题
          let wrongOption = wrongSelect[0];
          if (this.problem.choose) {
            for (const item of this.problem.choose) {
              this.chooseStyle.push("chooseLine");
            }
            if (Array.isArray(wrongOption)) {
              // 多选题
              wrongOption.forEach((item) => {
                const index = this.chooseNum.indexOf(item);
                this.chooseStyle[index] = "chooseLine lineChoose";
              });
            } else if (/^[\x00-\xff]/.test(wrongOption)) {
              console.log("单选", wrongOption);
              const index = this.chooseNum.indexOf(wrongOption);
              this.chooseStyle[index] = "chooseLine lineChoose";
            } else {
              const index = this.chooseBol.indexOf(wrongOption);
              this.chooseStyle[index] = "chooseLine lineChoose";
            }
          }
        }
      }
      // 题目总数
      this.allProblem = wrongTopics.length;
    },
  },
  async created() {
    this.activity = await globalData.getActivity();
    if (!_.isEmpty(this.activity)) {
      this.activityId = this.activity.id;
      await this.initProb(true);
    } else {
      this.back();
    }
  },
};
</script>
